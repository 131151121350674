var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('div',{staticClass:"allInputBox"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [{ required: true, message: '请输入注册邮箱' }, { validator: _vm.handleUsernameOrEmail }],
              validateTrigger: 'change'
            }
          ]),expression:"[\n            'username',\n            {\n              rules: [{ required: true, message: '请输入注册邮箱' }, { validator: handleUsernameOrEmail }],\n              validateTrigger: 'change'\n            }\n          ]"}],attrs:{"size":"large","type":"text","placeholder":"请输入注册邮箱"}})],1)]),_c('a-form-item',[_c('div',{staticClass:"allInputBox"},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', { rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur' }]),expression:"['password', { rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur' }]"}],attrs:{"size":"large","placeholder":"请输入密码"}})],1)]),_c('a-form-item',[_c('div',{staticClass:"code allInputBox"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code', { rules: [{ required: true, message: '请输入验证码' }], validateTrigger: 'blur' }]),expression:"['code', { rules: [{ required: true, message: '请输入验证码' }], validateTrigger: 'blur' }]"}],ref:"codeInput",attrs:{"autocomplete":"off","size":"large","placeholder":"请输入验证码"}}),_c('img',{attrs:{"src":_vm.codeUrl,"alt":""},on:{"click":_vm.getCode}})],1)]),_c('a-form-item',{staticStyle:{"margin-top":"14px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn}},[_vm._v("登录")])],1),_c('div',{staticClass:"user-login-other"},[(_vm.updatePassword)?_c('router-link',{staticClass:"forge-password",staticStyle:{"float":"left"},attrs:{"to":{ name: 'password', params: { user: 'aaa' } }}},[_vm._v(" 忘记密码 ")]):_vm._e(),(_vm.allowRegister)?_c('router-link',{staticClass:"register",attrs:{"to":{ name: 'register' }}},[_c('span',{staticStyle:{"color":"#000000"}},[_vm._v("还没账户？")]),_vm._v("去注册")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }